const ReviewsData = {
    reviews: [
        {
            name: "Davinia Hernández",
            comment: "Le hemos regalado el reloj a mi abuelo, lleva un mes con él y estamos encantados. Funciona de maravilla. Lo recomiendo. Además, tiene una relación calidad precio muy buena y una estética bonita. La única pega que le pondría es que no puede mojarse.",
            createTime: "15/2/2024",
            reviewer: {
                profilePhotoUrl: "https://lh3.googleusercontent.com/a/ACg8ocIsEooaSdHn3slhNlk79Gi1g62dXcDoigt7UAINJnzG=w72-h72-p-rp-mo-br100"
            }
        },
        {
            name: "Silvia del Prez",
            comment: "Llevamos ya un año con el reloj; gracias a él, aunque yo estoy con ella ahora, cuando salimos,estamos tranquilas.\n" +
                "El servicio del reloj, maravilloso,la gente súper amable siempre (y eso que mi madre,no para de darle sin querer al botón).\n" +
                "Hoy tuve que renovar la suscripción y la chica que me atendió,ha sido muy muy agradable y simpática.\n" +
                "Muchas gracias",
            createTime: "9/2/2024",
            reviewer: {
                profilePhotoUrl: "https://lh3.googleusercontent.com/a/ACg8ocJjuvIuiKi3Gr3--Zk2FDvb5fZy_3VDLHnrMyxuPKUo=w72-h72-p-rp-mo-br100"
            }
        },
        {
            name: "Pilar Pueyo",
            comment: "Es muy buen dispositivo de localización y emergencia, personal super profesional y amable, trato excelente",
            createTime: "8/2/2024",
            reviewer: {
                profilePhotoUrl: "https://lh3.googleusercontent.com/a/ACg8ocJ4RB3QVoxQtjvrKz72V5PVKbsRhCAxKfaLmZlcLsV5=w72-h72-p-rp-mo-ba2-br100"
            }
        },
        {
            name: "Lourdes González Burcio",
            comment: "La atención telefonica es excepcional. En mi caso me atendió Jessica y resolvió todos mis problemas y me ayudo a configurar y poner al día todos los datos.\n" +
                "El servicio de Reloj es muy bueno. Cubre todas las necesidades y nos aporta tranquilidad cuando mi padre sale de casa.",
            createTime: "25/1/2024",
            reviewer: {
                profilePhotoUrl: "https://lh3.googleusercontent.com/a/ACg8ocIZ6cviiNbYAbKl7i95wCYGUcMCgs612nlBAGdDBi0=w72-h72-p-rp-mo-br100"
            }
        },
        {
            name: "Rosa María Plaza",
            comment: "Siempre que se ha tocado el reloj o este a recibido un golpe , aunque fuera fortuito, ha habido una respuesta inmediata",
            createTime: "29/12/2023",
            reviewer: {
                profilePhotoUrl: "https://lh3.googleusercontent.com/a/ACg8ocL_ChaCksurhjtpZhLrLS9HjTudSR9JDUg63amwwUbd=w72-h72-p-rp-mo-br100"
            }
        },
        {
            name: "Dunia Elisenda Vidal Gomà",
            comment: "La mamá de mi marido, en muchas ocasiones, no nos contestaba cuando la llamábamos por teléfono lo cual nos generaba una enorme inquietud, hasta el punto de tener que desplazarnos a su domicilio. Gracias al reloj DÚRCAL sabemos como y dónde está en cada momento. Que tranquilidad nos ha quedado. Sólo por eso ya merece la pena comprarlo. Lo recomiendo.",
            createTime: "27/12/2023",
            reviewer: {
                profilePhotoUrl: "https://lh3.googleusercontent.com/a/ACg8ocLhAqpduEywLtJAINsYj_8PDwjVcG16u1y7EWjS4wJE=w72-h72-p-rp-mo-br100"
            }
        }
    ]
}

export default ReviewsData