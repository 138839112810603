import React, { useRef, useState } from "react"
//import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import iCloseVideo from "../../images/close.png"

const VideoElement = ({
  videosURL,
  intl,
  marginTop,
  caption,
  color,
  poster = "",
  showVideo
}) => {

  const videoRef = useRef(null)
  const VideoWrapper = useRef(null);
  const [isVideoVisible, setIsVideoVisible] = useState(true)

  const handleVideoVisibility = () => {
    setIsVideoVisible(true);
    videoRef.current.play();

  }

  const handleClick = function () {
    showVideo(false,null);
  }

  return (

      <VideoDashboardWrapper {...{marginTop, color}} ref={VideoWrapper}>
        <VideoContainer>
          <div className="closeContainer" onClick={handleClick}><img src={iCloseVideo}/></div>
          <VisibilitySensor
              active={!isVideoVisible}
              onChange={e => e && handleVideoVisibility()}
              partialVisibility
          >
            <video
                autoPlay={true}
                ref={videoRef}
                muted
                width="100%"
                //height="75%"
                playsInline
                preload="auto"
                poster={poster}
                loop
                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen','Close']}
            >
              <source src={videosURL} type="video/mp4"/>
            </video>
          </VisibilitySensor>
        </VideoContainer>
      </VideoDashboardWrapper>
  )
}

export default injectIntl(VideoElement)

const VideoDashboardWrapper = styled.div`
  margin-bottom: 16px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};
  position: fixed;
  top:35%;
  z-index: 5000;
  @media (min-width: ${props => props.theme.screen.xl}) {
    top:0;
  }
  @media (orientation: landscape) {
    top: 10px;
  }
  video {
   // width: 220px;
    height: 90%;
    //margin-top: 70px;
  }
  .closeContainer {
    z-index:10000;
    top:30px;
    left: 90%;
    position: absolute;
    @media (min-width: ${props => props.theme.screen.xl}) {
      top: 80px;
    }
    @media (orientation: landscape) {
      top: 70px;
    }
    img {
      width: 20px;
      height: 20px;
      @media (min-width: ${props => props.theme.screen.xl}) {
        width: 40px;
        height: 40px;
      }
    }
    
  }
  .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    left: 90%;

    @media (min-width: ${props => props.theme.screen.xl}) {
      width: 40px;
      height: 40px;
      top: 80px;
    }
    @media (orientation: landscape) {
      top: 70px;
    }
  }

  .caption {

    ${({ theme }) => theme.font_size.small};
    color: ${({ theme, color }) =>
      color ? theme.color[color] : theme.color.grey.grey02};
    letter-spacing: 4px;
    text-align: left;
    text-transform: uppercase;
  }
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
`

const ImageWrapper = styled.div`
  position: absolute;
`

const StyledImage = styled(Img)`
  width: 425px;
`
