import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
import styled from "styled-components";


const VideoButton = ({showVideo,video,eventType,buttonText}) => {

    return (
        <Button
                onClick={()=>{window.dataLayer.push({'event': 'videoClick','eventType': eventType});showVideo(video,true)}}
                btnType="primary"
                rounded
                fullwidth={false}
                arrow={true}
        >
            <ButtonText>{buttonText}</ButtonText>
        </Button>
    )
}

const ButtonText = styled.div`
  width: 150px;
  margin: 0;
  text-align: center;
  ${({theme}) => theme.font_size.regular};
  font-family: ${({theme}) => theme.font.bold};
`
export default injectIntl(VideoButton)
