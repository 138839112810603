import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import {graphql, useStaticQuery} from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import VideoButton from "../commonV3/VideoButton";

const Stories = ({intl,showVideo}) => {
    const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "stories" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                originalName
                src
                srcSet
                srcSetWebp
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `)

    return (
        <StoriesSectionWrapper>
            <h5>El impacto de Durcal en sus vidas</h5>
            <StoriesWrapper>

                <div className="StoryWrapper">
                    <StyledImage fluid={images.AllPostImages.edges[2].node
                        .childImageSharp.fluid} />
                    <div className="Description">
                        <div className="Name">
                            <div>Dolores</div>
                        </div>
                        <div className="NameSub">{intl.formatMessage({id: "storiesHome"})}</div>
                        <VideoButton showVideo={showVideo} video={"Dolores"} eventType="story1" buttonText={intl.formatMessage({id: "seeVideo"})}/>
                    </div>
                </div>
                <div className="StoryWrapper">
                    <StyledImage fluid={images.AllPostImages.edges[0].node
                        .childImageSharp.fluid} />
                    <div className="Description">
                        <div className="Name">
                            <div>Lourdes</div>
                        </div>
                        <div className="NameSub">{intl.formatMessage({id: "storiesFall"})}</div>
                        <VideoButton showVideo={showVideo} video={"Lourdes"} eventType="story2" buttonText={intl.formatMessage({id: "seeVideo"})}/>
                    </div>
                </div>
                <div className="StoryWrapper">
                    <StyledImage fluid={images.AllPostImages.edges[1].node
                        .childImageSharp.fluid} />
                    <div className="Description">
                        <div className="Name">
                            <div>Mariam</div>
                        </div>
                        <div className="NameSub">{intl.formatMessage({id: "storiesVoice"})}</div>
                        <VideoButton showVideo={showVideo} video={"Mariam"} eventType="story3" buttonText={intl.formatMessage({id: "seeVideo"})}/>
                    </div>
                </div>
            </StoriesWrapper>
        </StoriesSectionWrapper>
    )
}

const StoriesSectionWrapper = styled.div`
  margin: 16px 16px 0 16px;
  align-content: center;
  text-align: center;
    h5 {
    margin: 0;
    ${({ theme }) => theme.font_size.medium};
    font-family: ${({ theme }) => theme.font.bold};
    @media (min-width: ${props => props.theme.screen.xl}) {
      ${({ theme }) => theme.font_size.xlarge};
    }

  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row-reverse;
    gap:56px;
    padding: 40px;
  }
`

const StoriesWrapper = styled.div`
  margin: 16px 16px 0 16px;
  text-align: left;

  @media (min-width: ${props => props.theme.screen.xl}) {
    margin: 64px 64px 0 64px;
    flex-direction: row;
  }
  svg {fill:#4528B3}
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  gap: 24px;
  
  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  
  .StoryWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
    
    .Description {
      padding-top: 18px;
      padding-left: 24px;
      padding-bottom: 18px;
      background-color: #FFF;
      border-radius: 0 0 20px 20px;
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.thin};
      .Name {
        display: flex;
        flex-direction: row;
        align-items: center;
        ${({ theme }) => theme.font_size.large};
        font-family: ${({ theme }) => theme.font.bold};     
        margin-bottom: 18px;
        justify-content: space-between;
        .like {
          ${({ theme }) => theme.font_size.regular};
          font-family: ${({ theme }) => theme.font.thin};
          padding-right: 16px;
        }
      }
      .NameSub {
        margin-bottom: 18px;
        min-height: 40px;
      }
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({ theme }) => theme.font_size.regular};
      }
    }
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  display: block;
  border-radius: 20px 20px 0 0;
  @media (min-width: ${props => props.theme.screen.xl}) {
    //border-radius: 20px;
  }
`


export default injectIntl(Stories)
