//import React, {useState, useEffect} from "react"
import React, {useState} from "react"
import styled from "styled-components"
import {injectIntl} from "gatsby-plugin-intl";
import Img from "gatsby-image"
import Slider from "react-slick";
import IFiveStars from "../../images/svgs/FiveStarts.svg"
import ReviewsData from "../../static/reviews/reviewsData";

const Reviews = () => {
    function LeftNavButton(props) {
        const {className, style, onClick} = props
        return (
          <div
            className="slick-arrow"
            style={{...style, }}
            onClick={onClick}
          >
              &lt;
          </div>
        );
    }
    function RightNavButton(props) {
        const {className, style, onClick} = props
        return (
          <div
            className="slick-arrow"
            style={{...style,left:'100%' }}
            onClick={onClick}
          >
              &gt;
          </div>
        );
    }
    const [nav1, setNav1] = useState();
    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: "ease-in",
        prevArrow: <LeftNavButton />,
        nextArrow: <RightNavButton />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <ReviewsSectionWrapper>
            <h5>Nuestros clientes opinan</h5>
            <SliderWrapper>
                <Slider {...sliderSettings} asNavFor={nav1} ref={(slider1) => setNav1(slider1)}>
                    {ReviewsData.reviews.map(value => (
                        <ReviewWrapper>
                            <div className="userInfoDiv">
                                <img
                                    src={value.reviewer.profilePhotoUrl}
                                />
                                <div>
                                    <div className="Name">{value.name}</div>
                                    <div className="Date">{value.createTime}</div>
                                </div>
                            </div>
                            <IFiveStars/>
                            <div className="Description">{value.comment}</div>
                        </ReviewWrapper>
                    ))}
                </Slider>
            </SliderWrapper>
        </ReviewsSectionWrapper>
    )
}

const ReviewsSectionWrapper = styled.div`
  margin-bottom: 80px;
  
    h5 {
      text-align: center;
      margin: 0 0 16px 16px;
      ${({theme}) => theme.font_size.medium};
      font-family: ${({theme}) => theme.font.bold};
      @media (min-width: ${props => props.theme.screen.xl}) {
        ${({theme}) => theme.font_size.xlarge};
        margin-bottom: 40px;
      }
    }

  @media (min-width: ${props => props.theme.screen.xl}) {
    padding: 0 40px 0 40px;
  }
`

const ReviewWrapper = styled.div`
  background-color: #FFF;
  border-radius: 20px;
  padding: 32px;
  min-height: 336px;
  
  .userInfoDiv {
    display: flex;
    flex-direction: row;
    gap: 16px;
    
    img {
      border-radius: 50%;
      width: 42px;
      height: 42px;
    }
    
    .Name {
      ${({theme}) => theme.font_size.regular};
      font-family: ${({theme}) => theme.font.bold};
    }

    .Date {
      margin-top: 4px;
      ${({theme}) => theme.font_size.small};
      font-family: ${({theme}) => theme.font.thin};
    }
  }
  
  svg {
    margin-top: 16px;
  }
  
  .Description {
    margin-top: 16px;
    ${({theme}) => theme.font_size.regular};
    font-family: ${({theme}) => theme.font.thin};
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  padding: 0 56px 0 40px;

  .slick-track {
    display: flex;
    gap: 56px;
  }
    .slick-arrow {
        display: block;
        position: absolute;
        top: 45%;
        font-size: 35px;
        z-index: 1000;
        cursor: pointer;
    }
`

const StyledPhoto = styled(Img)`
  width: 220px;
`

export default injectIntl(Reviews)
